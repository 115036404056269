import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import baSummary from "data/json/ba-summary.json";
import baFeatures from "data/json/ba-features.json";
import baSentimentData from "data/json/ba-sentiment-examples.json";
import baSentimentCounts from "data/json/ba-sentiment-counts.json";
import baAllSentiment from "data/json/ba-all-sentiment.json";
import baProfanity from "data/json/ba-profanity.json";
import baCommonPhrases from "data/json/ba-common-phrases.json";
import baQuizData from "data/json/ba-quiz.json";
import { CaptionWrapper, ColoredSpan, HorizontalBarGraph, MultiBarGraph, NarrowContainer, PodcastAllSentiments, Quiz, SelectableMultiBarGraph, SentimentScoreTable, StyledTable } from "story_components";
import { SliderProvider } from "providers";
import { defaultSentimentOptions, generateTooltipData } from "data/beautiful-analysis.js";
import { colorMap } from "data/beautiful-analysis.js";
import COLORS from "utils/styles";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>
      <MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p"><MDXTag name="a" components={components} parentName="em" props={{
            "href": "https://www.earwolf.com/show/beautiful-anonymous/"
          }}>{`Beautiful Stories From Anonymous People`}</MDXTag></MDXTag>{`
(a.k.a. `}<MDXTag name="em" components={components} parentName="p">{`Beautiful / Anonymous`}</MDXTag>{`) is a podcast with a simple premise: "One phone
call. One hour. No names. No holds barred." Created by comedian
`}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://en.wikipedia.org/wiki/Chris_Gethard"
        }}>{`Chris Gethard`}</MDXTag>{` in 2016, every
episode features Gethard and an anonymous caller, who phones in to the show for
a one-hour conversation. The caller can hang up at any time, but Gethard must
stay on the line for the full hour.`}</MDXTag>
      <MDXTag name="p" components={components}>{`What began as an experimental idea has evolved into a rich show that captures
moments of real human connection. Sometimes these moments are funny, as one
might expect from a podcast hosted by a comedian. More often, though, they
hit different notes. Conversations span a wide variety of topics: anxiety about
the future, the death of a loved one, relationship struggles and triumphs, and,
sometimes, the regularity of one's bowel movements.`}</MDXTag>
      <MDXTag name="p" components={components}>{`In a society that often feels increasingly disconnected, these weekly calls are
a helpful reminder of our shared humanity.`}</MDXTag>
      <MDXTag name="p" components={components}>{`It probably goes without saying that I'm a fan of the podcast, and Gethard's
work in general. He wears many hats on the show: comedian, confidant,
empathizer, friend. But if there's one thing Gethard is not, it's a lover of
mathematics.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Gethard's negative math feelings are well-documented. Even his
listeners are aware of it. For example, in `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.earwolf.com/episode/deaf/"
        }}>{`one episode`}</MDXTag>{`
Gethard chats with a deaf caller via the aid of a sign language interpreter. At
one point, the topic of lipreading comes up. Here's what the caller has to say
(emphasis added):`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`I'm actually a pretty bad lipreader, even though I do use it. That's
something with lipreading, that's another pet peeve that a lot of deaf people
have, is expecting to lipread. I mean, we do it out of survival, but `}<MDXTag name="strong" components={components} parentName="p">{`I almost
look at lipreading the way that you talk about how much you hate math.`}</MDXTag></MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`This disdain for mathematics even creeps into Gethard's other works! In his 2018
book `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.amazon.com/Lose-Well-Chris-Gethard-ebook/dp/B076P4Q652"
        }}>{`Lose Well`}</MDXTag>{`,
while writing about some of his experiences teaching improv classes, Gethard
writes (again, emphasis added):`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`My students were very good, but they had developed the bad habit of turning
their art form into a math problem to solve. `}<MDXTag name="strong" components={components} parentName="p">{`Art can never be math.`}</MDXTag></MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`To be honest, I can't blame him. Unfortunately, most people have had at least
one traumatic math experience during their school years. But if you're like Chris Gethard `}{`—`}{` if
math makes you feel varying degrees of anxiety, repulsion, existential dread,
and / or rage `}{`—`}{` I'd like to invite you to turn the tables with me, and examine
some of Gethard's work through a mathematical lens.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Gethard may not have a high opinion of math, but what does math have to say
about him? Let's find out!`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Beautiful Basics`}</MDXTag>
      <MDXTag name="p" components={components}>{`As mentioned above, one `}<MDXTag name="em" components={components} parentName="p">{`Beautiful / Anonymous`}</MDXTag>{` episode features a deaf caller.
This experience inspired Gethard to provide transcripts of the show for fans
who might not be able to listen to them. In 2019, he released transcripts for 10
episodes curated as part of a `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://medium.com/@chrisgeth/a-beginners-guide-to-beautiful-anonymous-dffa5a520f52"
        }}>{`beginner's
guide`}</MDXTag>{`
to the podcast.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Unfortunately for Gethard, in so doing he opened a Pandora's box of material for
analysis. I went through all of those transcripts and crunched the numbers,
trying to answer questions like:`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">{`How much talking does Gethard do compared to his callers?`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`How does the `}<MDXTag name="em" components={components} parentName="li">{`sentiment`}</MDXTag>{` of a typical call change? In general are the conversations
positive, negative, or neutral in their content?`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Given a random line from a transcript, what are some clues that the line
came from Gethard as opposed to someone calling in?`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`To what extent can we train a computer to talk like Chris Gethard?`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`In what follows, I'd like to offer some answers to all of these questions. Some
caveats and clarifications:`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">{`Gethard opens and closes every episode with a few minutes of talking directly
to the listener. He also reads ads for a few minutes. I've
removed all the intro, outro, and ad text from this analysis. The only text I've
considered here is the text that comes from conversation with the caller.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Because I only have ten episodes to analyze, rather than the entire library
(which, as of this writing, numbers over 170 episodes), some of the results
you'll see here are biased by the small sample size. More on this below.`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`With that said, let's check out the results!`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Conversation Domination`}</MDXTag>
      <MDXTag name="p" components={components}>{`One basic question we can explore is how much each person speaks during a call.
We don't have timestamps available on the transcripts, so instead we'll use `}<MDXTag name="em" components={components} parentName="p">{`word
count`}</MDXTag>{` as a proxy.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here's how the number of words spoken by Gethard compares to the number of words
spoken by the caller, for each of the ten episode transcripts:`}</MDXTag>
      <CaptionWrapper caption="Figure 1: Word counts per episode for Gethard and the caller. Tap or mouse over a bar for more details on the episode.">
  <MultiBarGraph colors={[COLORS.DARK_BLUE, COLORS.ORANGE]} data={baSummary} getTooltipData={generateTooltipData} legendTitle="Word counts per episode" padding={{
          top: 0,
          left: 70,
          right: 0,
          bottom: 10
        }} yAxisLabel="Word count" />
      </CaptionWrapper>
      <MDXTag name="p" components={components}>{`My biggest takeaway from this graph is that Gethard seems to have gotten much
more comfortable creating space for his guests. He spoke the most in the very
first episode: 6,394 words, more than 67% of the total.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Overall, though, his guests tend to say more than he does. Gethard only speaks a
majority of the words in `}<MDXTag name="strong" components={components} parentName="p">{`three`}</MDXTag>{` of these ten episodes, and across the ten
transcribed episodes his share of the words stands at around 44%. If we exclude
his particularly chatty first episode, his share drops to 42%.`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Getting Sentimental`}</MDXTag>
      <MDXTag name="p" components={components}>{`Another thing we can do with these transcripts is analyze the sentiment of the
text. As people are talking, do their sentences have a positive sentiment, a
negative sentiment, or a neutral sentiment?`}</MDXTag>
      <MDXTag name="p" components={components}>{`This may seem like a difficult thing to pin down, especially if we'd like to
automate the analysis. Some statements are straightforward: we can probably
agree that "I love you" should count as having positive sentiment, and "I hate
you" should have negative sentiment.`}</MDXTag>
      <MDXTag name="p" components={components}>{`But what about a sentence like "Sometimes I love you, and sometimes I hate you." Is
this positive? Negative? Neutral? And how do you teach a computer how to assess
the sentiment so that we don't have to manually do for every transcript?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Fortunately, there's an off-the-shelf solution here works well. It's called
VADER Sentiment Analysis (short for `}<MDXTag name="strong" components={components} parentName="p">{`V`}</MDXTag>{`alence `}<MDXTag name="strong" components={components} parentName="p">{`A`}</MDXTag>{`ware `}<MDXTag name="strong" components={components} parentName="p">{`D`}</MDXTag>{`ictionary and
s`}<MDXTag name="strong" components={components} parentName="p">{`E`}</MDXTag>{`ntiment `}<MDXTag name="strong" components={components} parentName="p">{`R`}</MDXTag>{`easoner). It's basically a giant lexicon of words along with
their associated sentiment. The text was originally pulled from social media,
but, according to the maintainers of the project, it "is also generally
applicable to sentiment analysis in other domains."`}</MDXTag>
      <MDXTag name="p" components={components}>{`VADER can take a text and analyze it sentence-by-sentence for positive,
negative, and neutral sentiment. It spits out a few different stats, but the one
we'll use gives each sentence a score between -1 and 1. The closer to 1, the more
positive the sentiment; the closer to -1, the more negative the sentiment.`}</MDXTag>
      <MDXTag name="p" components={components}>{`To make things concrete, here are some examples of Gethard statements, along
with their associated sentiment score:`}</MDXTag>
      <SentimentScoreTable sentences={baSentimentData} caption="Figure 2: Sentiment scores for different things Chris Gethard has said." options={defaultSentimentOptions} />
      <MDXTag name="p" components={components}>{`VADER's not perfect. In particular, some of the "neutral" examples don't seem
all that neutral. But in the aggregate this can provide us with a useful metric
for how positive or negative someone's speaking patterns might be.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let's once again take a look at how Gethard compares to his callers. How many
times do they each talk about things with positive, negative, or neutral
sentiment? Here's a breakdown by sentence and by episode:`}</MDXTag>
      <CaptionWrapper caption="Figure 3: Sentiment counts per episode for Gethard and the caller. Use the dropdown to view different ranges of sentiment.">
  <SelectableMultiBarGraph colors={[COLORS.DARK_BLUE, COLORS.ORANGE]} containerWidth="86%" data={baSentimentCounts} getTooltipData={generateTooltipData} legendTitle="Sentiment counts per episode" options={defaultSentimentOptions} padding={{
          top: 10,
          left: 70,
          right: 0,
          bottom: 10
        }} yAxisLabel="Sentiment count" />
      </CaptionWrapper>
      <MDXTag name="p" components={components}>{`As you can see, positive sentiment greatly outweighs negative sentiment across
episodes. And for his part, Gethard plays the extremes pretty evenly: he speaks
a majority of sentences with extreme `}<MDXTag name="em" components={components} parentName="p">{`negative`}</MDXTag>{` sentiment in six out of ten
episodes, and the majority of sentences with extreme `}<MDXTag name="em" components={components} parentName="p">{`positive`}</MDXTag>{` sentiment in six
out of ten episodes.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We can take this even further by plotting the sentiment of every line in an
episode's transcript. Here's how the episodes look:`}</MDXTag>
      <PodcastAllSentiments caption="Figure 4: Sentiment of every line in an episode.
The larger the circle, the more words in the line." data={baAllSentiment} />
      <MDXTag name="p" components={components}>{`As you can see, most circles lie in the upper half, i.e. they have positive
sentiment. So if you're looking for a show that's positive on the whole, this
one fits the bill.`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Sorry Sally`}</MDXTag>
      <MDXTag name="p" components={components}>{`It's become somewhat of a tradition on the show for people to apologize to Chris
Gethard's mom, Sally, whenever they curse. So before we move on from sentiment,
here's a related question: who owes Sally a bigger apology, Chris or his
callers?`}</MDXTag>
      <MDXTag name="p" components={components}>{`There are a number of ways to try to detect profanity in a piece of text: the
one I used is called
`}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://github.com/vzhou842/profanity-check"
        }}>{`profanity-check`}</MDXTag>{`. Using this tool,
I was able to categorize every sentence in every conversation as either having
profanity or not. Here are the results, again broken down by speaker:`}</MDXTag>
      <CaptionWrapper caption="Figure 5: Profanity counts per episode for Gethard and the caller. Tap or mouse over a bar for more details on the episode.">
  <MultiBarGraph colors={[COLORS.DARK_BLUE, COLORS.ORANGE]} data={baProfanity} getTooltipData={generateTooltipData} legendTitle="Profanity counts per episode" padding={{
          top: 0,
          left: 70,
          right: 0,
          bottom: 10
        }} yAxisLabel="Profanity count" />
      </CaptionWrapper>
      <MDXTag name="p" components={components}>{`Sorry, Sally. It looks like your son takes the crown when it comes to profane
language. In 9 out of 10 transcripts, he had the majority of sentences marked as
profane. The only caller with a dirtier mouth was the Australian caller featured
in the "Aussie Best Friend" episode.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Gethard may take comfort in the fact that the profanity checker is easily
offended, so sentences like "I'm not trying to be a jerk," or "That sucks" both
get marked as profane. While this may indicate that the above counts are too
high, the ratio between Gethard and his guests is probably about right, since
the profanity checker has a low bar regardless of who is speaking.`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Whose Line is it Anyway?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let's move on to our next question: can we find any distinguishing
characteristics of the way Gethard speaks compared to his callers?`}</MDXTag>
      <MDXTag name="p" components={components}>{`One way to answer this question is to identify short phrases that appear
relatively frequently in the transcripts. Here are the most common two-, three-,
and four-word phrases spoken by Gethard and the callers as a group:`}</MDXTag>
      <CaptionWrapper caption="Figure 6: Common phrases for Gethard and his callers.
Phrases are restricted to the twenty most frequent, and each phrase must have
appeared an average of at least once per episode.">
  <SliderProvider initialData={[{
          min: baCommonPhrases.common_phrases[0].phrase_count,
          max: baCommonPhrases.common_phrases[2].phrase_count,
          initialValue: baCommonPhrases.common_phrases[0].phrase_count,
          step: 1,
          tickCount: 3,
          title: val => `${val}-word phrases`
        }]} render={([phraseCount]) => {
          const {
            speakers
          } = baCommonPhrases.common_phrases[phraseCount - 2];
          const numRows = Math.max(speakers["Chris"].length, speakers["Caller"].length);
          const rows = Array.from({
            length: numRows
          }, (_, i) => [speakers["Chris"][i], speakers["Caller"][i]]);
          return <StyledTable padding="0.1rem">
          <thead>
            <tr>
              {Object.keys(speakers).map(speaker => <th key={speaker}>
                  <ColoredSpan bold color={colorMap[speaker]}>
                    {speaker}
                  </ColoredSpan>
                </th>)}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => <tr key={`${phraseCount}-${i}`}>
                {Object.keys(speakers).map((speaker, idx) => <td key={speaker}>
                    <ColoredSpan color={colorMap[speaker]}>
                      {rows[i][idx] ? `${rows[i][idx][0]} (said ${rows[i][idx][1]} times)` : "--"}
                    </ColoredSpan>
                  </td>)}
              </tr>)}
          </tbody>
        </StyledTable>;
        }} />
      </CaptionWrapper>
      <MDXTag name="p" components={components}>{`One difference you may notice is how often common phrases include the word "I."
The data suggests that Gethard is good at centering the conversation on the
caller and their experiences. Callers say "I" in `}<MDXTag name="strong" components={components} parentName="p">{`eight`}</MDXTag>{` of their top twenty
most common two-word phrases, but Gethard only says "I" in `}<MDXTag name="strong" components={components} parentName="p">{`five`}</MDXTag>{` of his.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The phrases in the table above are common to Gethard and his callers, but
they're also just plain common. Knowing that Gethard says "and I" 126 times
across these ten episode transcripts doesn't really tell us that much about what
makes Gethard sound like Gethard.`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="p" components={components}>{`Another way we can study speech patterns in these transcripts is through
`}<MDXTag name="strong" components={components} parentName="p">{`collocations`}</MDXTag>{`, a linguistic term for sequences of words that occur
disproportionately often.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here are the two-word collocations for Chris Gethard and his callers:`}</MDXTag>
      <CaptionWrapper caption="Figure 7: Collocations for Gethard and his callers.">
  <NarrowContainer width="70%" fullWidthAt="small">
    <StyledTable padding="0.1rem">
      <thead>
        <tr>
          {baCommonPhrases.collocation_lists.map(({
                  speaker
                }) => <th key={speaker}>
              <ColoredSpan bold color={colorMap[speaker]}>
                {speaker}
              </ColoredSpan>
            </th>)}
        </tr>
      </thead>
      <tbody>
        {baCommonPhrases.collocation_lists[0].collocations.map((phrase, idx) => <tr key={idx}>
              <td>
                <ColoredSpan color={colorMap["Chris"]}>{phrase}</ColoredSpan>
              </td>
              <td>
                <ColoredSpan color={colorMap["Caller"]}>
                  {baCommonPhrases.collocation_lists[1].collocations[idx]}
                </ColoredSpan>
              </td>
            </tr>)}
      </tbody>
    </StyledTable>
  </NarrowContainer>
      </CaptionWrapper>
      <MDXTag name="p" components={components}>{`A few observations:`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">{`Chris Gethard talks about New York City a lot.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`People talk about high school even more than Gethard talks about New York
City. The phrase appears at least once in nine out of the ten episodes!`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Because of the small sample size, it's
possible for a phrase to show up here even if it's only discussed in a single
episode. Among Gethard's collocations, "Bank Tech," "bromance test," "sign
language," "Ron Paul," and "uncanny valley" only appear in a single episode each.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Because none of the callers are robots, none of them actually say "mmhmm
affirmative." This is a case of metadata in the transcripts messing with the
analysis. Since sounds like "mmhmm" can be ambiguous, the transcripts include a
parenthetical describing what the sound means. In other words, the collcation
"mmhmm affirmative" corresponds to something like "Mm-hmm (affirmative)" in the
transcripts.`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`Collocations do a better job at telling us about some unique topics that come up
on `}<MDXTag name="em" components={components} parentName="p">{`Beautiful / Anonymous`}</MDXTag>{`. But, except maybe for the way Gethard seems to talk
about New York a lot, they don't really answer the original question of how we
can distinguish between what Gethard says and what his callers say. For this, we
need a slightly different approach.`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Transcript Training`}</MDXTag>
      <MDXTag name="p" components={components}>{`Collocations tell us whether a sequences of words appears more often than is
typical. But we're not interested in comparing word frequency to an absolute
average; we're interested in comparing Gethard's word frequency to the average
frequency of his callers. This is a slightly different problem, since, for
instance, his callers may themselves bring up topics more often than is typical
(high school, for example).`}</MDXTag>
      <MDXTag name="p" components={components}>{`So how can we do this? One way is to try to find `}<MDXTag name="em" components={components} parentName="p">{`linguistic tells`}</MDXTag>{` that will
accurately predict when Chris is talking as opposed to one of his callers.`}</MDXTag>
      <MDXTag name="p" components={components}>{`This is a common task within the field of `}<MDXTag name="em" components={components} parentName="p">{`machine learning`}</MDXTag>{`. Without taking us
too far afield, here's the idea: basically, you tell a computer about certain
features of a text that you think may help predict the speaker. For example,
Chris Gethard may be more likely to talk about New York and New Jersey than his
callers.`}</MDXTag>
      <MDXTag name="p" components={components}>{`You can give the computer dozens or hundreds of these features to consider, as
well as a test set of data to look at (basically a random subset of all of the
transcripts). The computer will then `}<MDXTag name="em" components={components} parentName="p">{`learn`}</MDXTag>{` how to best optimize and balance
these features so that, given a new line, it has a high probability of being
able to correctly guess who said it.`}</MDXTag>
      <MDXTag name="p" components={components}>{`I threw the kitchen sink at my computer in terms of features I thought might
have some predictive power. In the end, this yielded an algorithm that could
accurately predict whether or not Gethard was the speaker of a random line with
about 70% accuracy. Here are the top twenty most informative features for one of
my training sessions:`}</MDXTag>
      <CaptionWrapper caption="Figure 8: Twenty most informative features for
distinguishing between Gethard and his callers.">
  <HorizontalBarGraph data={baFeatures.map(d => ({ ...d,
          fill: colorMap[d.width > 0 ? "Chris" : "Caller"]
        }))} />
      </CaptionWrapper>
      <MDXTag name="p" components={components}>{`The numbers in the above chart indicate the strength of each predictor. For
example, a line where the first word is "wow" is 12.9 times more likely to have
come from Gethard, while a line containing the word "definitely" is 14.1 times
more likely to have come from one of his callers.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The data here is consistent with what we've already seen. Some observations:`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">{`Lines that center the conversation on the other person are more likely to
come from Gethard. Notice that when the most common word in a line is "your" or
when the first word is "you're", the speaker is much more likely to be Gethard;
on the other hand, his guests are much more likely to have the first or most
common word in a line by "my."`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Similar to 1., notice that lines with "what's" in them are much more likely
to be spoken by Gethard. Perhaps his focus on callers implies that he tends to
ask more questions than they do.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Wow, Chris Gethard loves saying "wow!"`}</MDXTag>
      </MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`RoboGeth`}</MDXTag>
      <MDXTag name="p" components={components}>{`One last thing before we wrap up. We've used computers to search for predictors
of Gethard's speech patterns, but can we go further? Can we train a machine to
talk like Chris Gethard?`}</MDXTag>
      <MDXTag name="p" components={components}>{`I tried, and you can be the judge.`}</MDXTag>
      <MDXTag name="p" components={components}>{`I took all of Gethard's lines from the ten conversations, and trained a
`}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://github.com/minimaxir/textgenrnn"
        }}>{`text-generating neural network`}</MDXTag>{` on
them. Want to see how I did? Let's play a game.`}</MDXTag>
      <Quiz answerColors={[COLORS.DARK_BLUE, COLORS.PURPLE]} caption="Figure 9: Chris vs. Computer: who said it? This 10 question quiz pulls from 24 examples, so you can play it a few times to see different sentences." questionData={baQuizData} title="Who said it: Chris Gethard or an Algorithm?" />
      <MDXTag name="p" components={components}>{`How did you do? If you didn't get 100%, don't worry. The robots aren't coming
for Gethard's job anytime soon.`}</MDXTag>
      <MDXTag name="p" components={components}>{`I intentionally selected the cream of the algorithm-generated crop. There was
plenty of text that was more clearly nonsense. Here's an example:`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`It sounds like you're going to look into it. I didn't want to have to ask. I
had to know your dad gets an except and then also an environment while there in
1996 and when you say it too more about that. You must be a good point. I think
that's a tragic baby. I listen for her new life minutes left. `}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`And another one:`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`Oh, wow. Now, I feel like me. I don't want to make it about me in a way that he
never counting the deaf community to me on the started point of things out loud
about it, and then you are a strength to the guy everywhere.`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`And finally:`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`You and your husband are like, "Why is your kids?"?`}</MDXTag>
      </MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Conclusion`}</MDXTag>
      <MDXTag name="p" components={components}>{`Having crunched the numbers, the verdict is in. Mathematics has a pretty
positive view of Gethard, even if the feelings aren't reciprocated. `}</MDXTag>
      <MDXTag name="p" components={components}>{`He walks the walk by refraining from talking the talk. He gives space to his
callers, frames the conversation around them, and maintains an overall positive
sentiment. This is borne out in his conversational tells (his penchant for
asking questions, favoring "your" over "my", etc). `}</MDXTag>
      <MDXTag name="p" components={components}>{`Gethard frequently talks about his show as giving a platform for everyday
people, and the data backs up this claim.If you haven't listened to the show,
I'd encourage you to give it a go. At its best, it captures what it means to be
human at this point in history. Each episode is its own master class in
listening and empathy.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Though, as I think his mother would agree, Gethard could cut down on the
profanity a bit.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Sources:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "https://medium.com/@chrisgeth/a-beginners-guide-to-beautiful-anonymous-dffa5a520f52"
            }}>{`A Beginner's Guide to Beautiful Anonymous`}</MDXTag>{`, by Chris Gethard.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "https://victorzhou.com/blog/better-profanity-detection-with-scikit-learn/"
            }}>{`Building a Better Profanity Detection Library with scikit-learn`}</MDXTag>{`, by Victor Zhou.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "http://www.nltk.org/book/"
            }}>{`Natural Language Processing with Python – Analyzing Text with the Natural Language Toolkit`}</MDXTag>{`, by Steven Bird, Ewan Klein, and Edward Loper.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "https://medium.com/@sharonwoo/sentiment-analysis-with-nltk-422e0f794b8"
            }}>{`Sentiment analysis with NLTK / VADER — Comments on Lee Hsien Loong’s Facebook post`}</MDXTag>{`, by Sharon Woo.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "https://medium.com/analytics-vidhya/simplifying-social-media-sentiment-analysis-using-vader-in-python-f9e6ec6fc52f"
            }}>{`Simplifying Sentiment Analysis using VADER in Python (on Social Media Text)`}</MDXTag>{`, by Parul Pandey.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "https://github.com/cjhutto/vaderSentiment"
            }}>{`Vader Sentiment`}</MDXTag>{` on GitHub.`}</MDXTag>
        </MDXTag>
      </MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {
  "title": "Beautiful / Analysis",
  "date": "2019-08-29",
  "featured_image": "../../images/featured_images/beautiful_analysis.jpg",
  "caption": "What does math have to say about a guy who'd rather not think about math?",
  "featured_image_caption": "Comedian Chris Gethard, host of Beautiful / Anonymous. Image credit: NJ.com",
  "tags": ["podcasts", "statistics", "machine learning", "natural language processing"]
};
      